import React, { useContext, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { doc, onSnapshot } from 'firebase/firestore'
import styled, { ThemeContext } from 'styled-components'
import { Box, Button, Flex, Heading, Image, Text, Link as ExternalLink } from 'rebass/styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'
import { Label, Input } from '@rebass/forms/styled-components'
import { loginUser } from '../models/Users'
import Logo from '../assets/images/mot-logo.svg'
import { setGlobals } from '../system/redux/reducers/globals'
import { db } from '../system/firebase/index'

const borderRadius = '1.84479px'

const LinkWithHover = styled(ExternalLink)`
    color: ${(props) => props.theme.colors.greyDark};
    text-decoration: none;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    &:hover, &.active {
        color: ${(props) => props.theme.colors.red};
        font-weight: ${(props) => props.theme.fontWeights.semibold};
    }
`

const BoxShadow = styled(Box)`
    box-shadow: 0px 3.68958px 3.68958px rgba(0, 0, 0, 0.2);
    border-radius: ${borderRadius};
`

const Login = ({ dispatch, unAuthorised, errors, globals }) => {
    const theme = useContext(ThemeContext)
    const [state, updateState] = useState({ email: '', password: '' })
    const loginErrors = errors ? <p> {errors} </p> : '';
    const notAuthorised = unAuthorised ? <p> You are not authorised! </p> : '';
    // function capitalizeFirstLetter(string) {
    //     return string.charAt(0).toUpperCase() + string.slice(1);
    // }
    // const title = capitalizeFirstLetter(process.env.GATSBY_CLOUDINARY_NAME)

    const handleSubmit = (event) => {
        const { email, password } = state
        event.preventDefault()

        loginUser(dispatch, { email, password })
    }

    const onInputChange = (name, event) => {
        const change = { ...state }
        change[name] = event.target.value
        updateState(change)
    }

    useEffect(() => {
        let isCancelled = false
        onSnapshot(doc(db, 'globals', 'globals'), (doc) => {
            if (!isCancelled) {
                const info = doc.data()
                dispatch(setGlobals(JSON.stringify({ name: info.website.title, primaryColor: info.theme.primary })))
            }
        })
        return () => {
            isCancelled = true;
        }
    }, [])

    return (
        <Flex width='100%' height='100%' alignItems="center" justifyContent='center' flexDirection='column'>
            <Flex justifyContent="center" alignItems="center" pb='md'>
                <Box variant='curvedBox' backgroundColor={globals.primaryColor} ml='xs' fontWeight='bold'> 
                    { 
                        globals && globals.environment !== 'production' ? globals.environment + (globals.environment !== '' ? ' - ' : ' ') + globals.version : globals.version
                    }
                </Box>
            </Flex>
            <Box width='100%' maxWidth='558px'>
                <BoxShadow>
                    <Box bg='white' px='md' pt='md' pb='sm' mb='1px' textAlign='center' sx={{
                        borderTopLeftRadius: borderRadius,
                        borderTopRightRadius: borderRadius,
                    }}>
                        <Heading variant='h3' as='h3' fontWeight='semibold'>{globals && globals.name}</Heading>
                        <Text as='p' fontSize='sm'>Welcome. Please login here.</Text>
                    </Box>
                    <Box bg='white' p='md' mb='sm' sx={{
                        borderBottomLeftRadius: borderRadius,
                        borderBottomRightRadius: borderRadius,
                    }}>
                        <Box as='form' onSubmit={(e) => handleSubmit(e)}>
                            <Flex alignItems="center" mb='sm'>
                                <Label htmlFor='email' maxWidth='100px'>Email:</Label>
                                <Input
                                    variant='inputSecondary'
                                    id='email'
                                    name='email'
                                    type='email'
                                    placeholder='jane@example.com'
                                    value={state.email}
                                    onChange={(e) => onInputChange( 'email', e )}
                                />
                            </Flex>
                            <Flex alignItems="center" mb='sm'>
                                <Label htmlFor='password' maxWidth='100px'>Password:</Label>
                                <Input
                                    variant='inputSecondary'
                                    id='password'
                                    name='password'
                                    type='password'
                                    placeholder='Password'
                                    value={state.password}
                                    onChange={(e) => onInputChange( 'password', e )}
                                />
                            </Flex>

                            {loginErrors}
                            {notAuthorised}
                            <Flex justifyContent="flex-end">
                                <Button backgroundColor={globals && globals.primaryColor} type='submit'>Login</Button>
                            </Flex>
                        </Box>
                    </Box>
                </BoxShadow>
                <Flex alignItems='center' justifyContent='space-between'>
                    <LinkWithHover href={process.env.GATSBY_WEB_ADDRESS} theme={theme}>
                        <Flex alignItems='center'>
                            <FontAwesomeIcon icon={faLongArrowAltLeft} /> <Text as='span' fontSize='sm' pl='xxs'>Go to Website</Text>
                        </Flex>
                    </LinkWithHover>
                    <Image src={Logo} />
                </Flex>
            </Box>
        </Flex>
    )
}

function mapStateToProps(state) {
    return {
        unAuthorised: state.Auth.unAuthorised,
        errors: state.Auth.errors,
        globals: state.Globals,
    };
}

export default connect(mapStateToProps)(Login);
